import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../components/why.scss"
import Section from "../components/section"
import SectionSubtitle from "../components/sectionSubtitle"
import Text from "../components/text"
import Footer from "../components/footer"
import Contact from "../components/contact"

const Personality = () => (
  <Layout>
    <SEO
      lang="it"
      title="Disturbo di personalita' | Stefano Maffina | Psicologo clinico | Gardone Val Trompia"
      description="Descrizione del disturbo di personalita' di Stefano Maffina, psicologo clinico"
    />
    <div className="navbar paddings-lr">
      <div style={{ width: "1050px", margin: "0 auto", paddingLeft: "16px" }}>
        <Link to="/">
          <span>Indietro</span>
        </Link>
      </div>
    </div>
    <Section isAbout color="#f8f8f8">
      <div style={{ paddingTop: "64px" }}>
        <div className="section-subtitle">
          <h2
            data-aos="custom-fade"
            data-aos-duration="1000"
            style={{ color: "#121212" }}
          >
            <span style={{ fontWeight: 700, textDecoration: "italic" }}>
              Disturbi di personalita'
            </span>{" "}
          </h2>
        </div>
        <div className="paddings-lr why-content">
          <p>
            I tratti di personalità diventano disturbi di personalità quando i
            modelli di comportamento della persona si discostano in modo marcato
            dalle norme culturali, e sono talmente rigidi e stabili nel tempo da
            causare un significativo disagio soggettivo o una compromissione di
            un’area importante del funzionamento. I disturbi di personalità si
            suddividono in: Paranoide Schizoide Antisociale Borderline
            Istrionico Narcisistico Evitante Dipendente Ossessivo-Compulsivo
          </p>
        </div>
      </div>
    </Section>
    <Contact />
    <Footer />
  </Layout>
)

export default Personality
